import React, { useContext } from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { breakpointContext } from './footer'

export type FooterLinksType = {
  text: string
  url: string
}

export type FooterBottomProps = {
    copyrightText?: string[]
    rightLinks?: FooterLinksType[]
    leftLinks?: FooterLinksType[]
    acknowledgement?: string
}

export const FooterBottomWrapper = styled(Grid)`
  background-color: ${props => props.theme.palette.common.white};
  padding-bottom: ${props => props.theme.typography.pxToRem(64)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(12)};
    padding: 0;
  }
`

export const FooterMid = styled(Grid)`
  background-color: ${props => props.theme.palette.common.white};
  height: 100%;
  padding-top: ${props => props.theme.typography.pxToRem(71)};
  padding-bottom: ${props => props.theme.typography.pxToRem(55)};
`

export const FooterAcknowledgeDiv = styled(Grid)`
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.common.white};
  height: 100%;
`

const CopyRightParagraph = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(20)};
    margin-bottom: ${props => props.theme.typography.pxToRem(38)};
  }
`

export const CopyRight = styled(Typography)`
  color: ${props => props.theme.palette.primary.dark};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  line-height: ${props => props.theme.typography.pxToRem(30)};
`

export const Acknowledgement = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  line-height: ${props => props.theme.typography.pxToRem(22)};
  margin-top: ${props => props.theme.typography.pxToRem(10)};
  margin-bottom: ${props => props.theme.typography.pxToRem(10)};
  padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)};
  background-color: ${props => props.theme.palette.background.default};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
    line-height: ${props => props.theme.typography.pxToRem(30)};
  }
`

const FooterLink = styled.a`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  text-align: left;
  text-decoration: underline;
  line-height: ${props => props.theme.typography.pxToRem(38)};

  &:hover {
    color: ${props => props.theme.palette.secondary.main};
  }

  &::before {
    content: "";
  }
`
const RightFooterLink = styled(FooterLink)`
  margin-left: 10px;
`

export const LinkList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
`

const RightFooterList = styled(Grid)`
  margin-left: ${props => props.theme.typography.pxToRem(20)};
`

const LeftFooterList = styled(Grid)`
  margin-left: ${props => props.theme.typography.pxToRem(10)};
`

export const UnstyledLink = styled.li`
  &::before {
    content: "";
    padding: 0;
  }
`

export const FooterBottom: React.FC<FooterBottomProps> = ({ acknowledgement, copyrightText, leftLinks, rightLinks }) => {
  const { mobile } = useContext(breakpointContext)
  
  return (
    <FooterBottomWrapper 
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        xs={10}
      >
        <FooterMid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction="row"
        >
          <CopyRightParagraph
            item
            md={mobile ? 3 : 12}
          >
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start">

            </Grid>
            {copyrightText && copyrightText.map((text, key) => <CopyRight key={key}>{text}</CopyRight>)}
          </CopyRightParagraph>
          <Grid
            item
            md={mobile ? 9 : 12}
          >
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent={!mobile ? 'flex-start' :'flex-end'}
            >
              <Grid
                item
                sm={6}
              >
                <LeftFooterList
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  container
                >
                  <LinkList>
                    {leftLinks && leftLinks.map((link, key) =><UnstyledLink key={key}>
                      <FooterLink href={link.url}>{link.text}</FooterLink>
                    </UnstyledLink>)}
                  </LinkList>
                </LeftFooterList>
              </Grid>

              <Grid
                item
                sm={4}
              >
                <RightFooterList
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <LinkList>
                    {rightLinks && rightLinks.map((link, key) =><UnstyledLink key={key}>
                      <RightFooterLink href={link.url}>{link.text}</RightFooterLink>
                    </UnstyledLink>)}
                  </LinkList>
                </RightFooterList>
              </Grid>

            </Grid>
          </Grid>

        </FooterMid>
      </Grid>
      <Grid
        item
        md={10}
      >
        <FooterAcknowledgeDiv 
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
        >
          <Acknowledgement>
            {acknowledgement}
          </Acknowledgement>

        </FooterAcknowledgeDiv>
      </Grid>

    </FooterBottomWrapper>
  )
}